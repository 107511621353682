<template>
  <AppLoginPageLayout>
    <div class="d-flex justify-center">
      <img
        src="@/assets/icons/logo_sourcery.svg">
    </div>
    <h1 class="form-login__container--title">
      Log In to Your Account
    </h1>
    <p
      v-if="merged"
      class="form-login__container--description">
      Your account has been set up, login again please.
    </p>
    <p
      v-if="loginInvitedUser"
      class="form-login__container--description">
      New password has been sent to your email.
    </p>
    <v-form
      ref="loginIn"
      @submit.prevent="signIn">
      <v-text-field
        v-model="initialCaseEmail"
        placeholder="Email"
        single-line
        filled
        dense
        data-test="sign_in_email_input"
        :error-messages="emailErrors"
        :append-icon="!!emailErrors.length? 'mdi-alert-circle-outline' : ''"
        prepend-inner-icon="mdi-email-outline"
        :error="!!emailErrors.length"
        autocomplete="email"
        name="login-email-address"
        type="email"
        @input="clearLoginErrorMessage('email')" />
      <v-text-field
        v-model="login.password"
        placeholder="Password"
        single-line
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        filled
        dense
        data-test="sign_in_password_input"
        :error-messages="passwordErrors"
        prepend-inner-icon="mdi-lock-outline"
        :error="!!passwordErrors.length"
        class="mt-6"
        name="login-password"
        autocomplete="current-password"
        @click:append="showPassword = !showPassword"
        @input="clearLoginErrorMessage('password')" />
      <ForgotPassword />
      <v-row class="mt-8 d-flex justify-center flex-column align-center">
        <v-btn
          :disabled="showSpinner"
          type="submit"
          data-test="log_in_button"
          color="lightBlue">
          Log In
        </v-btn>
        <p
          v-if="$vuetify.breakpoint.smAndDown"
          class="mt-2 small-p text-uppercase">
          <router-link :to="{ name: 'sign-up' }">
            Sign up
          </router-link>
        </p>
      </v-row>
    </v-form>
  </AppLoginPageLayout>
</template>
<script>
import {
  required, email, minLength,
} from 'vuelidate/lib/validators';
import AWS from '@/services/awsSdk';
import API from '@/services/graphql';
import AppLoginPageLayout from '@/components/App/AppLoginPageLayout';
import ConverEmailToLowerCase from '@/mixins/ConverEmailToLowerCase';
import ErrorsText from '@/constants/errors';
import {
  mapMutations, mapActions, mapState,
} from 'vuex';
export default {
  name: 'Login',
  components: {
    AppLoginPageLayout,
    ForgotPassword: () => import('@/components/App/AppLoginPageLayout/ForgotPassword'),
  },
  mixins: [ConverEmailToLowerCase],
  props: {
    email: {
      type: String,
      default: null,
    },
    merged: {
      type: Boolean,
      default: false,
    },
    loginInvitedUser: {
      type: Boolean,
      default: false,
    },
    forceLoginFlag: {
      type: Boolean,
      default: false,
    },
    password: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    error: null,
    showPassword: false,
    pageObjName: 'login',
    login: {
      email: null,
      password: null,
      initialCaseEmail: null,
    },
    hasOngoingLogin: false,
  }),
  computed: {
    ...mapState({
      showSpinner: state => state.showSpinner,
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.login.email.$dirty) return errors;
      if (!this.$v.login.email.email) {
        errors.push('Invalid e-mail address');
      }
      if (!this.$v.login.email.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.error && !this.error.includes('password')) {
        errors.push(this.error);
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.login.password.$dirty) return errors;
      if (!this.$v.login.password.minLength) {
        errors.push('Passwords must be at least 8 characters long');
      }
      if (!this.$v.login.password.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.error && this.error.includes('password')) {
        this.error == ErrorsText.ERROR_WITH_USERNAME_OR_PASSWORD ? errors.push(ErrorsText.ERROR_WITH_PASSWORD) : errors.push(this.error);
      }
      return errors;
    },
  },
  mounted() {
    const { email, password, forceLoginFlag } = this;
    if (forceLoginFlag) {
      this.forceLogin({
        email,
        password,
      });
    }
  },
  validations: {
    login: {
      email: {
        required, email,
      },
      password: {
        required, minLength: minLength(8),
      },
    },
  },
  async created() {
    this.clearListOfFilesForSubscriptions();
    this.clearListOfFilesToNotify();
    if (this.email) {
      this.initialCaseEmail = this.email;
    }
    const { uuid, email, password } = this.$route.query;
    this.setLoginRedirectFromQuery();
    if (uuid) {
      this.getTemporaryCredentials(uuid);
    } else if (email && password) {
      this.forceLogin({
        email, password,
      });
    }
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      clearListOfFilesForSubscriptions: 'ExportSchedule/clearListOfFilesForSubscriptions',
      clearListOfFilesToNotify: 'ExportSchedule/clearListOfFilesToNotify',
    }),
    ...mapActions({
      featureFlags: 'FeatureFlags/getListFeatureFlags',
    }),
    clearLoginErrorMessage() {
      this.error = null;
    },
    async getTemporaryCredentials(uuid) {
      try {
        const { data } = await API.getTemporaryCredentials({
          uuid,
        });
        if (data?.response) {
          this.forceLogin(data.response);
        }
      } catch (err) {
        console.log(err);
      }
    },
    forceLogin({ email, password } = {
    }) {
      if (email && password) {
        this.initialCaseEmail = email;
        this.login = {
          ...this.login,
          password,
        };
        this.signIn();
      }
    },
    async signIn() {
      if (this.hasOngoingLogin) return;

      try {
        this.hasOngoingLogin = true;
        this.$v.login.$touch();
        if (!this.$v.login.$invalid) {
          this.spinner(true);
          const { email, password } = this.login;
          const dataToSend = {
            email,
            password,
          };
          await AWS.login(dataToSend, this);
        }
      } finally {
        this.hasOngoingLogin = false;
      }
    },
    setLoginRedirectFromQuery() {
      const { resourceType, resourceId } = this.$route.query;
      if (!resourceType || !resourceId) return;
      sessionStorage.setItem('redirectFromQuery', true);
    },
  },
};
</script>
